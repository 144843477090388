import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import Button from "../button/button";
import ButtonContainer from "../button/buttonContainer";
import "./entryBlock.scss";

const EntryBlock = ({ queryData }) => {
  const image = queryData.bevezetKp
    ? getImage(queryData.bevezetKp)
    : getImage(queryData.bortkp);
  const description = queryData?.alcm;

  return (
    <div className="entry-block">
      <div className="entry-block-wrapper">
        <div className="entry-image">
          {image && <GatsbyImage image={image} alt="" />}
        </div>
        <div className="entry-data">
          <h4>{queryData.cm}</h4>
          <p>{description ? description : null}</p>
          <ButtonContainer>
            <Button
              url={queryData?.slug}
              text={"Érdekel"}
              icon={faArrowRight}
            />
          </ButtonContainer>
        </div>
      </div>
    </div>
  );
};
export default EntryBlock;
