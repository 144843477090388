import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./assetBlock.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const AssetBlock = ({ image }) => {
  const imageData = getImage(image);

  return (
    <div className="asset-block">
      {imageData && (
        <Zoom>
          <GatsbyImage image={imageData} alt="" />
        </Zoom>
      )}
    </div>
  );
};
export default AssetBlock;
