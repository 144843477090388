import * as React from "react";
import { useMemo } from "react";
import { graphql } from "gatsby";
import PageContent from "../../../components/layout/content";
import Layout from "../../../components/layout/layout";
import {
  FrameBlock100,
  FrameSectionRow,
} from "../../../components/basics/frame/frameBlocks";
import DoubleColumnRow from "../../../components/pageComponents/doubleColumnRow/doubleColumnRow";
import { getSrc } from "gatsby-plugin-image";
import Seo from "../../../components/basics/seo/seo";
import NoBackdropHeader from "../../../components/layout/header/nobackdrop/noBackdropHeader";
import IconButtonWithText from "../../../components/iconButton/iconButtonWithText";
import {
  faFacebookMessenger,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import "../../css/instructorPage.scss";
import "../../../theme/style.scss";

const InstructorsPage = ({ data }) => {
  const pageData = useMemo(() => data.contentfulOldal, [data]);

  const headerData = pageData?.header || pageData?.tartalomTpus?.header;
  const imageURL = getSrc(headerData?.bortkp);

  return (
    <Layout>
      <Seo
        title={pageData.cm}
        description={pageData.alcm}
        url={
          pageData?.kategria?.slug
            ? `${pageData?.kategria?.slug}/${pageData.slug}`
            : pageData.slug
        }
        imageUrl={imageURL}
      />
      <NoBackdropHeader queryData={headerData} />
      <PageContent>
        <DoubleColumnRow image={headerData?.bortkp} title="About me">
          <p>{pageData?.sszest?.sszest}</p>
          {pageData?.tartalomTpus?.services?.length && (
            <>
              <p>
                <b>Provided Services</b>
              </p>
              <ul>
                {pageData?.tartalomTpus?.services.map(({ oldal }, key) => (
                  <li key={oldal?.[0]?.cm + key}>{oldal?.[0]?.cm}</li>
                ))}
              </ul>
            </>
          )}
        </DoubleColumnRow>
        <FrameSectionRow>
          <FrameBlock100>
            <h3>Contact Information</h3>
            <div className="contact-information-row">
              <div className="contact-information-row-block">
                {pageData?.tartalomTpus?.phoneNumber && (
                  <IconButtonWithText
                    url={`tel:${pageData?.tartalomTpus?.phoneNumber}`}
                    externalLink
                    icon={faPhone}
                    text={pageData?.tartalomTpus?.phoneNumber}
                  />
                )}

                {pageData?.tartalomTpus?.whatsapp && (
                  <IconButtonWithText
                    url={
                      pageData?.tartalomTpus?.whatsapp?.includes("http")
                        ? pageData?.tartalomTpus?.whatsapp
                        : `https://${pageData?.tartalomTpus?.whatsapp}`
                    }
                    externalLink
                    openOnNewPage
                    icon={faWhatsapp}
                    text={pageData?.tartalomTpus?.whatsapp}
                  />
                )}
              </div>
              <div className="contact-information-row-block">
                {pageData?.tartalomTpus?.email && (
                  <IconButtonWithText
                    url={`mailto: ${pageData?.tartalomTpus?.email}`}
                    externalLink
                    icon={faEnvelope}
                    text={pageData?.tartalomTpus?.email}
                  />
                )}

                {pageData?.tartalomTpus?.messenger && (
                  <IconButtonWithText
                    url={
                      pageData?.tartalomTpus?.messenger?.includes("http")
                        ? pageData?.tartalomTpus?.messenger
                        : `https://${pageData?.tartalomTpus?.messenger}`
                    }
                    externalLink
                    openOnNewPage
                    icon={faFacebookMessenger}
                    text={pageData?.tartalomTpus?.messenger}
                  />
                )}
              </div>
            </div>
          </FrameBlock100>
        </FrameSectionRow>
        {pageData?.tartalomTpus?.tartalom &&
          pageData?.tartalomTpus?.tartalom?.map(
            (
              { cm, alcm, ctaGomb, kp, nagythatKp, tartalom, contentful_id },
              key,
            ) => (
              <DoubleColumnRow
                key={contentful_id}
                reverse={key % 2 === 0}
                image={kp}
                title={cm}
                subtitle={alcm}
                ctaGomb={ctaGomb}
                zoom={nagythatKp}
                content={tartalom}
              />
            ),
          )}
      </PageContent>
    </Layout>
  );
};

export const query = graphql`
  query instructor($contentfulId: String) {
    contentfulOldal(contentful_id: { eq: $contentfulId }) {
      slug
      cm
      sszest {
        sszest
      }
      alcm
      kategria {
        slug
      }
      tartalomTpus {
        ... on ContentfulInstructorsTartalomTipus {
          header {
            bortkp {
              gatsbyImageData(layout: CONSTRAINED)
              url
            }
            lers
            title
            primaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
            secondaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
          }
          whatsapp
          messenger
          email
          phoneNumber
          services {
            oldal {
              cm
            }
          }
          tartalom {
            ... on Contentful2OszloposSor {
              contentful_id
              cm
              alcm
              nagythatKp
              kp {
                gatsbyImageData(layout: CONSTRAINED)
              }
              tartalom {
                raw
              }
              ctaGomb {
                ... on ContentfulCtaGomb {
                  ctaGombLink {
                    ... on ContentfulOldal {
                      slug
                      kategria {
                        slug
                      }
                    }
                    ... on ContentfulOldalKategoria {
                      slug
                    }
                  }
                  ctaGombSzveg
                  externalCtaGombLink
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default InstructorsPage;
