import * as React from "react";
import { Link } from "gatsby";

const EntryHyperlink = (props) => {
  return (
    <Link
      to={
        props?.categorySlug
          ? `/${props?.categorySlug / props?.categorySlug}`
          : props?.slug
      }
    >
      {props.children}
    </Link>
  );
};
export default EntryHyperlink;
