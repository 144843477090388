import * as React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { FrameBlock50, FrameSectionRow } from "../../basics/frame/frameBlocks";
import ImageDisplay from "../../basics/imageDisplay/imageDisplay";
import richTextOptions from "../../richTextOptions/richTextOptions";
import PropTypes from "prop-types";
import ButtonContainer from "../../basics/button/buttonContainer";
import Button from "../../basics/button/button";
import { useCallback } from "react";
import { useMemo } from "react";

/**
 * This component is used for displaying a row with 2 columns: 1 image 1 text.
 * @param {boolean} themed - sets the background color to the theme colour
 * @param {string} image - contentful image
 * @param {boolean} zoom - set the ability to zoom into the image
 * @param {string} title - title of this row
 * @param {string} subtitle - subtitle of this row
 * @param {object} content - contentful richtextContent
 * @param {string} textContent - text content
 * @param {boolean} reverse - reverse image/text order
 * @param {string} ctaLink - relative path for cta button link
 * @param {string} ctaText - cta button text
 * @param {object} ctaGomb - cta Gomb data from contentful
 */

const DoubleColumnRow = ({
  themed = false,
  children,
  image,
  zoom = false,
  title,
  subtitle,
  content,
  textContent,
  reverse = false,
  ctaLink,
  ctaText,
  ctaGomb,
}) => {
  const getLink = useCallback(() => {
    if (ctaLink) {
      return ctaLink;
    }
    if (ctaGomb?.ctaGombLink?.slug) {
      if (ctaGomb?.ctaGombLink?.kategria) {
        return `/${ctaGomb?.ctaGombLink?.kategria?.slug}/${ctaGomb?.ctaGombLink?.slug}`;
      }
      return `/${ctaGomb?.ctaGombLink?.slug}`;
    }
    if (ctaGomb?.externalCtaGombLink) {
      return ctaGomb?.externalCtaGombLink;
    }
    return false;
  }, [ctaGomb]);
  const getText = useCallback(() => {
    if (ctaText) {
      return ctaText;
    }
    if (ctaGomb?.ctaGombSzveg) {
      return ctaGomb?.ctaGombSzveg;
    }
    return false;
  }, [ctaGomb]);

  const isExternalLink = useMemo(() => {
    if (
      ctaGomb?.externalCtaGombLink !== "" &&
      !ctaGomb?.ctaGombLink?.slug &&
      !ctaLink
    ) {
      return true;
    }
    return false;
  }, []);

  return (
    <FrameSectionRow themed={themed} reverse={reverse}>
      <FrameBlock50>
        <ImageDisplay zoom={zoom} image={image} />
      </FrameBlock50>
      <FrameBlock50>
        <p className="title-accent">{subtitle}</p>
        <h3>{title}</h3>
        {content && renderRichText(content, richTextOptions)}
        {textContent && <p>{textContent}</p>}
        {children}
        {getLink() && getLink() && (
          <ButtonContainer>
            <Button
              externalLink={isExternalLink}
              text={getText()}
              url={getLink()}
            />
          </ButtonContainer>
        )}
      </FrameBlock50>
    </FrameSectionRow>
  );
};
export default DoubleColumnRow;

DoubleColumnRow.propTypes = {
  themed: PropTypes.bool,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  /**
   * Contentful richText content
   */
  content: PropTypes.object,
  textContent: PropTypes.string,
  reverse: PropTypes.bool,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  zoom: PropTypes.bool,
};
