import { Link } from "gatsby";
import * as React from "react";
import "./entryBlock.scss";

const InlineEntryBlock = (props) => {
  return (
    <Link
      className="inline-entry-link"
      to={
        props?.categorySlug
          ? `/${props?.categorySlug / props?.categorySlug}`
          : props?.slug
      }
    >
      <span className="inline-entry-block">
        <span className="inline-entry-block-wrapper">
          <b>{props.title}</b>
        </span>
      </span>
    </Link>
  );
};
export default InlineEntryBlock;
