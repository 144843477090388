import * as React from "react";

const AssetLink = ({ url, children }) => {
  return (
    <a className="asset-link" href={url}>
      {children}
    </a>
  );
};
export default AssetLink;
