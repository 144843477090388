import * as React from "react";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import AssetBlock from "../basics/embeds/assetBlock";
import AssetLink from "../basics/embeds/assetLink";
import EntryBlock from "../basics/embeds/entryBlock";
import EntryHyperlink from "../basics/embeds/entryHyperlink";
import InlineEntryBlock from "../basics/embeds/inlineEntryBlock";

const richTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
    [MARKS.ITALIC]: (text) => <i>{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    [MARKS.CODE]: (text) => <code>{text}</code>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data;
      return <a href={uri}>{children}</a>;
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return (
        <EntryHyperlink
          categoryslug={node.data.categoryslug}
          slug={node.data.slug}
        >
          {children}
        </EntryHyperlink>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <AssetLink assetId={node.data.target.sys.id}>{children}</AssetLink>
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      return (
        <InlineEntryBlock
          categoryslug={node.data.categoryslug}
          title={node.data.title}
          slug={node.data.slug}
        />
      );
    },
    [BLOCKS.TABLE]: (node, children) => {
      return <div className="table">{children}</div>;
    },
    [BLOCKS.TABLE_ROW]: (node, children) => {
      return <div className="table-body-row">{children}</div>;
    },
    [BLOCKS.TABLE_CELL]: (node, children) => {
      return <div className="table-body">{children}</div>;
    },
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
      return <div className="table-header">{children}</div>;
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className="text-center">{children}</h1>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="text-center">{children}</h2>;
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className="text-center">{children}</h3>;
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <h4 className="text-center">{children}</h4>;
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return <h5 className="text-center">{children}</h5>;
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return <h6 className="text-center">{children}</h6>;
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{children}</p>;
    },
    [BLOCKS.HR]: (node, children) => {
      return <hr />;
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className="ol-list">{children}</ol>;
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className="ul-list">{children}</ul>;
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li>{children}</li>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      return <EntryBlock queryData={node.data.target} />;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      return <AssetBlock imageData={node.data.target} />;
    },
  },
};

export default richTextOptions;
